import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { PiSoccerBall } from "react-icons/pi";
import { TbBuildingCottage } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

function FacilitiesUser() {
  const navigate = useNavigate();

  return (
    <UserScreenWindow label="Facilities Booking">
      <div className="text-center text-sm font-bold text-blue-900 mt-4">
        Select from Available Facilities Below
      </div>

      <div className="flex items-center justify-center gap-4 mt-8">
        <div
          className="flex flex-col items-center justify-center cursor-pointer hover:bg-blue-200 rounded-xl p-2"
          onClick={() => navigate("/pickle-ball")}
        >
          <div className="text-4xl">
            <PiSoccerBall />
          </div>
          <div className="text-xs text-gray-600">Pickle Ball Court</div>
        </div>
        <div
          className="flex flex-col items-center justify-center cursor-pointer hover:bg-blue-200 rounded-xl p-2"
          onClick={() => navigate("/club-house")}
        >
          <div className="text-4xl">
            <TbBuildingCottage />
          </div>
          <div className="text-xs text-gray-600">Club House</div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default FacilitiesUser;

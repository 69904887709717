import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function BillingDetails() {
  const [billDetails, setBillDetails] = React.useState(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBillDetails = async () => {
      const data = await getDocData("billings", id);
      setBillDetails(data);
      setIsLoading(false);
    };
    fetchBillDetails();
  }, []);

  //firebasedate converter
  const date = new Date(
    billDetails?.dueDate?.seconds * 1000
  ).toLocaleDateString();

  if (isLoading) {
    return (
      <UserScreenWindow label="Billing Details">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const handleUpload = () => {
    toast("Uploading...");
    setTimeout(() => {
      toast.success("Upload Successful!");
    }, 2000);

    setTimeout(() => {
      toast.success("Payment Uploaded!");
      navigate(`/payment-confirmed/${id}`);
    }, 4000);
  };

  return (
    <UserScreenWindow label="Billing Details">
      <div>
        <div className="font-bold text-blue-900 text-center text-sm mt-8 mb-4">
          Billing Details
        </div>

        <div className="p-2">
          <div>Billing For: {billDetails.identifier}</div>
          <div>Bill Type: {billDetails.billingType}</div>
          <div>Due Date: {date}</div>
          <div>
            Total Amount: Php{" "}
            {parseFloat(billDetails.amount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
          <div>Description: {billDetails.description}</div>
        </div>

        <div>
          <button
            className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full"
            onClick={() => navigate(`/payment-confirmed/${id}`)}
          >
            Pay Now
          </button>
        </div>

        <div className="mt-4">
          <label>or Upload Deposit Slip</label>
          <input type="file" onChange={handleUpload} />
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default BillingDetails;

import React from "react";
import { FiHome } from "react-icons/fi";
import { FiShield } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { IoChatbubblesOutline } from "react-icons/io5";

function UserNavBar() {
  const [activeLink, setActiveLink] = React.useState("Home");

  const navItems = [
    {
      icon: <FiHome />,
      label: "Home",
    },
    {
      icon: <FiShield />,
      label: "Admin",
    },
    {
      icon: <IoChatbubblesOutline />,
      label: "Group Chat",
    },
    {
      icon: <FiUser />,
      label: "Profile",
    },
  ];

  const activeLinkStyle = "text-green-700 bg-green-100 rounded-lg";
  const navigate = useNavigate();

  const handleClick = (link) => {
    setActiveLink(link);
    navigate(`/${link.toLowerCase()}`);
  };

  return (
    <div className="flex items-center justify-center gap-8 bg-gray-200 p-2 rounded-t-xl pb-10 mx-auto max-w-[450px] w-screen">
      {navItems.map((item, index) => (
        <div
          key={index}
          className={`flex flex-col items-center p-2
          ${activeLink === item.label ? activeLinkStyle : "text-gray-500"}
            `}
          onClick={() => handleClick(item.label)}
        >
          <div className="text-2xl">{item.icon}</div>
          <div className="text-xs font-semibold">{item.label}</div>
        </div>
      ))}
    </div>
  );
}

export default UserNavBar;

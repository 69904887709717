import React from "react";
import { Link, NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import WorkIcon from "@mui/icons-material/Work";
import InventoryIcon from "@mui/icons-material/Inventory";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CampaignIcon from "@mui/icons-material/Campaign";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { MdAnalytics } from "react-icons/md";
import { TiContacts } from "react-icons/ti";

import ApartmentIcon from "@mui/icons-material/Apartment";

function Navbar() {
  const [activeLink, setActiveLink] = React.useState("overview");

  const navbaritems = [
    {
      name: "Overview",
      icon: <DashboardIcon />,
      link: "/overview",
    },
    {
      name: "Resident Management",
      icon: <ReceiptLongIcon />,
      link: "/resident-management",
    },
    {
      name: "Property Management",
      icon: <WorkIcon />,
      link: "/property-management",
    },
    {
      name: "Security",
      icon: <LocalPoliceIcon />,
      link: "/security",
    },
    {
      name: "Facilities",
      icon: <ApartmentIcon />,
      link: "/facilities",
    },

    {
      name: "Inventory",
      icon: <InventoryIcon />,
      link: "/inventory",
    },
    {
      name: "Analytics",
      icon: <MdAnalytics />,
      link: "/analytics",
    },

    {
      name: "Announcements",
      icon: <CampaignIcon />,
      link: "/announcements",
    },
    {
      name: "Billing",
      icon: <SummarizeIcon />,
      link: "/billing",
    },
    {
      name: "Contacts",
      icon: <TiContacts />,
      link: "/services",
    },
    {
      name: "HOA",
      icon: <ContactMailIcon />,
      link: "/contacts",
    },
  ];

  return (
    <div className="navbar z-10 bg-blue-900 w-screen h-[40px] text-white flex items-center justify-center poppins-regular shadow-gray-600 shadow-lg sticky top-0">
      <div>
        <ul className="flex items-center gap-2">
          {navbaritems.map((item, index) => (
            <div
              key={index}
              className={`${
                activeLink === item.name.toLowerCase()
                  ? "bg-green-500 text-gray-800"
                  : "hover:bg-green-500 hover:text-gray-800"
              } cursor-pointer hover:bg-green-500 hover:text-gray-800 p-2 rounded-md transition-all duration-300 ease-in-out text-xs py-1`}
            >
              <NavLink
                to={item.link}
                onClick={() => setActiveLink(item.name.toLowerCase())}
              >
                <div className="flex items-center gap-1">
                  <div className="text-lg"> {item.icon}</div>
                  <span>{item.name}</span>
                </div>
              </NavLink>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Navbar;

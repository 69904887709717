import React from "react";

function SplashScreen({ setShowModal }) {
  const handleModal = () => {
    setShowModal(false);
  };
  return (
    <div className="relative min-w-[350px] min-h-[500px] p-2">
      <button
        className="underline text-xs absolute right-0 top-0"
        onClick={handleModal}
      >
        Close
      </button>
      <div className="pt-8 font-bold text-blue-900 ">
        Welcome to Tali Beach Admin Portal
      </div>
      <div className="font-semibold text-gray-600 text-center">
        Powered by Elegho
      </div>
      <div>
        <img
          src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713873813/elegho/Elegho-Logo_vah1pv.png"
          alt=""
          className="w-[100px] mx-auto"
        />
      </div>
      <div>This platform allows you to conduct the following</div>
      <div>
        <ul className="list-disc list-inside text-left mt-8 text-sm text-gray-600">
          <li>Manage Users</li>
          <li>Manage Requests</li>
          <li>Manage Payments</li>
          <li>Manage Notifications</li>
          <li>Manage Directory</li>
          <li>Manage Clubhouse Reservations</li>
          <li>Manage Pickleball Reservations</li>
          <li>Manage Group Chats</li>
          <li>Manage Gatepass</li>
        </ul>
      </div>
    </div>
  );
}

export default SplashScreen;

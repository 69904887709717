import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { setDocData } from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

function ClubHouse() {
  const [formData, setFormData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [assignedId, setAssignedId] = React.useState(v4());

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const docObject = {
      ...formData,
      status: "pending",
      type: "clubhouse",
      user: currentUser.email,
      dateSubmitted: new Date().toISOString(),
    };
    setLoading(true);
    await setDocData("facilityRequests", assignedId, docObject);
    setLoading(false);
    setFormData({});
    toast.success("Club House Reservation Request Submitted");
    navigate("/reservation-confirmation/" + assignedId);
  };

  const handleSelect = (e) => {
    setFormData({ ...formData, duration: e.target.value });
  };

  return (
    <UserScreenWindow label={"Club House Reservation"}>
      <div className="text-center font-bold text-sm text-blue-900 mt-8">
        Club House Reservation
      </div>
      <div>
        <div className="mt-4">
          <label className="block text-sm font-bold">Select Date</label>
          <input
            type="date"
            className="w-full border border-gray-300 rounded-lg p-2 mt-1"
            name="date"
            onChange={handleChange}
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-bold">Select Time</label>
          <input
            type="time"
            className="w-full border border-gray-300 rounded-lg p-2 mt-1"
            name="time"
            onChange={handleChange}
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-bold">Select Duration</label>
          <select
            className="w-full border border-gray-300 rounded-lg p-2 mt-1"
            name="duration"
            onChange={handleSelect}
          >
            <option>Select Number of Hours</option>
            <option>1 Hour</option>
            <option>2 Hours</option>
            <option>3 Hours</option>
            <option>4 Hours</option>
          </select>
        </div>
        <div className="mt-4">
          <button
            className="w-full bg-blue-900 text-white rounded-lg p-2"
            onClick={handleSubmit}
          >
            {loading ? "Submitting" : "Reserve"}
          </button>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default ClubHouse;

import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function CleaningServicesUser() {
  return (
    <UserScreenWindow label="Cleaning Services">
      CleaningServicesUser
    </UserScreenWindow>
  );
}

export default CleaningServicesUser;

import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileSettings from "../Settings/ProfileSettings";

import UserAccess from "../Settings/UserManagement";

function Settings() {
  const [activeLink, setActiveLink] = React.useState("profile");

  const settingsMenuItems = [
    {
      name: "Profile",
      icon: <AccountCircleIcon />,
      link: "profile",
    },
    {
      name: "User Management",
      icon: <AccountCircleIcon />,
      link: "usermanagement",
    },
  ];

  return (
    <div>
      <div className="font-bold text-blue-900 text-xl text-left">Settings</div>
      <div className="flex items-start justify-normal gap-4">
        <div className="flex flex-col gap-2 border text-xs w-60 p-2">
          {settingsMenuItems.map((item, index) => (
            <div
              key={index}
              className={` cursor-pointer text-left ${
                activeLink === item.link
                  ? "text-blue-900 font-bold "
                  : " text-gray-600"
              }`}
              onClick={() => setActiveLink(item.link)}
            >
              <div>{item.name}</div>
            </div>
          ))}
        </div>
        <div className="">
          {activeLink === "profile" && <ProfileSettings />}
          {activeLink === "usermanagement" && <UserAccess />}
        </div>
      </div>
    </div>
  );
}

export default Settings;

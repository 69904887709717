import React from "react";
import Map from "../../assets/Tali-Beach-Map.jpg";

function TaliMap() {
  return (
    <div>
      <div>TaliMap</div>
      <div className="text-center">
        <img
          src={Map}
          alt="Tali Beach Map"
          className="w-[90%] h-auto mx-auto"
        />
      </div>
    </div>
  );
}

export default TaliMap;

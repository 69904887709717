import React from "react";

function Modal({ children }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-2 rounded-xl">{children}</div>
    </div>
  );
}

export default Modal;

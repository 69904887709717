import React from "react";
import { Link } from "react-router-dom";

function UserUnderConstruction() {
  return (
    <div className="bg-blue-900 flex flex-col items-center justify-center h-screen text-white p-2 gap-4">
      <div>Your Administrator is being setup. Please come back later.</div>
      <Link className="underline" to="/">
        Go to Home
      </Link>
    </div>
  );
}

export default UserUnderConstruction;

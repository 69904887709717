import React from "react";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";

function Signup() {
  return (
    <div
      className="h-screen w-screen  poppins-regular 
    bg-[url('https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]
    bg-cover bg-center bg-no-repeat
    "
    >
      <div className="bg-white bg-opacity-50 h-full w-full flex flex-col items-center justify-center">
        <div>
          <SignupForm />
        </div>
      </div>
    </div>
  );
}

export default Signup;

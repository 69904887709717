import React from "react";

function WaterMeterReading() {
  return (
    <div className="p-2 text-blue-900">
      <div className="flex items-center justify-between">
        <div className="font-bold text-xl ">Water Meter Reading</div>
        <div className="flex items-center justify-normal gap-2">
          <button className="bg-blue-900 text-white px-4 py-2 rounded-md">
            Generate Water Bills
          </button>
          <button className="bg-blue-900 text-white px-4 py-2 rounded-md">
            Send General Reminders
          </button>
        </div>
      </div>
    </div>
  );
}

export default WaterMeterReading;

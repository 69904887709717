import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function OtherServicesUser() {
  return (
    <UserScreenWindow label="Other Service Providers">
      <div className="text-center mt-8 font-bold text-blue-900">
      
        Coming soon...
      </div>
    </UserScreenWindow>
  );
}

export default OtherServicesUser;

import React from "react";
import { QRCodeSVG } from "qrcode.react";

function GuestCard({ guestDetails }) {

  return (
    <div className="relative h-36 p-2 border-b border-gray-200 text-sm text-gray-500">
      <div className="text-left font-bold text-blue-900">
        {guestDetails.date}
      </div>
      <div className="grid grid-cols-2 text-left text-xs">
        <div>
          <div>Scheduled Time</div>
          <div>{guestDetails.time}</div>
        </div>
        <div>
          <div>Time In</div>
          <div>{guestDetails.timeIn || "No time information"}</div>
        </div>
      </div>

      <div className="text-left mt-4 text-xs">Guest Names and Vehicle </div>
      <div className="mt-2  font-bold">
        {guestDetails.guests.map((guest, index) => (
          <div key={index} className="text-xs  grid grid-cols-2 text-left">
            <div>{guest.name}</div>
            <div>{guest.plateNumber}</div>
          </div>
        ))}
      </div>

      <div className="absolute flex flex-col items-center justify-center bottom-5 right-2">
        <QRCodeSVG
          value={`https://talielegho.vercel.app/guest-card-details/${guestDetails.id}`}
          size={50}
        />
      </div>
    </div>
  );
}

export default GuestCard;

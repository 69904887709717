import React, { useEffect } from "react";

function JobOrders() {
  const [activeFilter, setActiveFilter] = React.useState("All");
  const [filteredJobOrders, setFilteredJobOrders] = React.useState([]);

  const jobOrders = [
    {
      id: 1,
      unit: "Unit 101",
      description: "Leaking faucet",
      status: "Pending",
      date: "2021-09-01",
      photo: "https://via.placeholder.com/100",
      assignedTo: "Maintenance",
      dateAssigned: "2021-09-02",
      priority: "High",
      requestedBy: "John Doe",
    },
    {
      id: 2,
      unit: "Unit 102",
      description: "Broken window",
      status: "Resolved",
      date: "2021-09-01",
      dateResolved: "2021-09-02",
      photo: "https://via.placeholder.com/100",
      assignedTo: "Maintenance",
      priority: "High",
      requestedBy: "Jane Doe",
    },
    {
      id: 3,
      unit: "Unit 103",
      description: "Leaking roof",
      status: "Scheduled",
      date: "2021-09-01",
      dateScheduled: "2021-09-02",
      photo: "https://via.placeholder.com/100",
      assignedTo: "Maintenance",
      priority: "High",
      requestedBy: "John Wick",
    },
  ];

  const statusLinks = ["Pending", "Scheduled", "Resolved"];
  const tableHeaders = [
    "Unit",
    "Description",
    "Photo",
    "Status",
    "Date",
    "Assigned To",
    "Date Assigned",
    "Priority",
    "Requested By",
    "Actions",
  ];

  const filterLinks = ["All", "Pending", "Scheduled", "Resolved"];

  useEffect(() => {
    if (activeFilter === "All") {
      setFilteredJobOrders(jobOrders);
      return;
    }
    const filtered = jobOrders.filter(
      (jobOrder) => jobOrder.status === activeFilter
    );
    setFilteredJobOrders(filtered);
  }, [activeFilter]);

  const activeFilterStyle = "bg-green-500 text-white font-bold";

  return (
    <div className="text-left p-2">
      <div className="font-bold text-xl">Maintenance Request / Job orders</div>
      <div className="flex items-center justify-between  p-2 rounded-lg border-2 border-gray-300">
        <div>
          <input
            type="search"
            placeholder="Search by Job Order Number or Name"
            className="border-2 border-gray-300 px-4 py-2 rounded-lg w-96"
          />
        </div>
        <div className="flex items-center justify-between gap-4 ">
          {filterLinks.map((link, index) => (
            <div
              key={index}
              className={`cursor-pointer text-sm rounded-full border-2 border-gray-300 px-2 py-1 min-w-20 text-center ${
                activeFilter === link ? activeFilterStyle : "text-gray-600"
              }
                `}
              onClick={() => setActiveFilter(link)}
            >
              {link}
            </div>
          ))}
        </div>
      </div>
      <div className="text-xs">
        <table className="w-full">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredJobOrders.map((jobOrder, index) => (
              <tr key={index}>
                <td className="p-2">{jobOrder.unit}</td>
                <td className="p-2">{jobOrder.description}</td>
                <td className="p-2">
                  <img src={jobOrder.photo} alt="job order" />
                </td>
                <td className="p-2">{jobOrder.status}</td>
                <td className="p-2">{jobOrder.date}</td>
                <td className="p-2">{jobOrder.assignedTo}</td>
                <td className="p-2">{jobOrder.dateAssigned}</td>
                <td className="p-2">{jobOrder.priority}</td>
                <td className="p-2">{jobOrder.requestedBy}</td>
                <td className="p-2">
                  <button className="bg-green-500 text-white p-2 rounded-md">
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default JobOrders;

import React from "react";

function CheckBox({ name, label, onChange, ...otherprops }) {
  return (
    <div className="flex items-center justify-start gap-2">
      <input name="plumbing" type="checkbox" {...otherprops} />
      <label className="capitalize">{label}</label>
    </div>
  );
}

export default CheckBox;

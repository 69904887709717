import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function Facilities() {
  const [activeLink, setActiveLink] = React.useState("");
  const [filteredRequests, setFilteredRequests] = React.useState([]);
  const [activeFilter, setActiveFilter] = React.useState("All");
  const [searchParams, setSearchParams] = useSearchParams();

  const facility = searchParams.get("facility");
  const filter = searchParams.get("filter");

  const facilitiesLinks = [
    "Gym",
    "Swimming Pool",
    "Beach Front",
    "Meeting Room",
    "Function Room",
  ];

  useEffect(() => {
    setActiveLink(facilitiesLinks[0]);
  }, []);

  useEffect(() => {
    setSearchParams({ facility: activeLink, filter: activeFilter });
  }, [activeLink, activeFilter]);

  const filters = ["Pending", "Approved", "Rejected", "All"];

  const facilitiesRequests = [
    {
      id: 1,
      requestType: "Gym",
      unit: "A-101",
      contactNumber: "08012345678",
      dateTime: "12/12/2021 12:00",
      requestedBy: "John Doe",
      documents: [],
      message: "Request for Gym Access",
      status: "Pending",
    },
    {
      id: 2,
      requestType: "Swimming Pool",
      unit: "A-101",
      contactNumber: "08012345678",
      dateTime: "12/12/2021 12:00",
      requestedBy: "John Doe",
      documents: [],
      message: "Request for Swimming Pool Access",
      status: "Approved",
    },
    {
      id: 3,
      requestType: "Gym",
      unit: "A-101",
      contactNumber: "08012345678",
      dateTime: "12/12/2021 12:00",
      requestedBy: "John Doe",
      documents: [],
      message: "Request for Gym Access",
      status: "Rejected",
      reasonForRejection: "Facility is under maintenance",
    },
    {
      id: 4,
      requestType: "Gym",
      unit: "A-101",
      contactNumber: "08012345678",
      dateTime: "12/12/2021 12:00",
      requestedBy: "John Doe",
      documents: [],
      message: "Request for Gym Access",
      status: "Pending",
    },
    {
      id: 5,
      requestType: "Beach Front",
      unit: "A-101",
      contactNumber: "08012345678",
      dateTime: "12/12/2021 12:00",
      requestedBy: "John Doe",
      documents: [],
      message: "Request for Gym Access",
      status: "Pending",
    },
  ];

  const tableHeaders = [
    "Request Type",
    "Unit",
    "Contact Number",
    "Date/Time",
    "Requested By",
    "Status",
    "Actions",
  ];

  const activeLinkStyle = "bg-green-500 text-black ";
  const activeFilterPillSytle =
    "bg-green-500 text-white p-2 rounded-full min-w-24 cursor-pointer";
  const inactiveFilterPillStyle =
    "bg-gray-300 text-black p-2 rounded-full min-w-24 cursor-pointer";

  useEffect(() => {
    const filtered = facilitiesRequests.filter((request) => {
      if (activeFilter === "All") {
        return request.requestType === activeLink;
      }
      return (
        request.requestType === activeLink && request.status === activeFilter
      );
    });
    setFilteredRequests(filtered);
  }, [searchParams]);

  return (
    <div className="p-2 text-blue-900 space-y-4">
      <div className="flex items-center justify-between">
        <div className="font-bold text-xl">Facilities</div>
        <div className="flex items-center space-x-2">
          <button className="bg-blue-900 text-white rounded-lg px-2 py-1">
            Edit
          </button>
          <button className="bg-blue-900 text-white rounded-lg px-2 py-1">
            Print
          </button>
        </div>
      </div>
      <div className="border-2 border-gray-300 p-2 flex items-center justify-between">
        <div className="border-2 border-gray-300 rounded-xl divide-x-2 flex items-center justify-normal overflow-hidden text-xs">
          {facilitiesLinks.map((facility, index) => (
            <div
              key={index}
              className={`cursor-pointer p-2 min-w-24
                ${activeLink === facility ? activeLinkStyle : ""}
                `}
              onClick={() => setActiveLink(facility)}
            >
              {facility}
            </div>
          ))}
        </div>
        <div className="space-x-4 ">
          <input
            type="text"
            placeholder="Search by Name, Request No."
            className="p-2 py-1 w-96 rounded-lg border-2 border-gray-300"
          />
          <button>Filter</button>
        </div>
      </div>
      <div className="text-xs">
        <div className="flex items-center gap-4">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`${
                filter === activeFilter
                  ? activeFilterPillSytle
                  : inactiveFilterPillStyle
              }`}
              onClick={() => setActiveFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
      </div>
      <div className="text-xs">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="border border-gray-300 p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredRequests.map((request, index) => (
              <tr key={index}>
                <td className="border border-gray-300 p-2 font-bold">
                  {request.requestType}
                </td>
                <td className="border border-gray-300 p-2">{request.unit}</td>
                <td className="border border-gray-300 p-2">
                  {request.contactNumber}
                </td>
                <td className="border border-gray-300 p-2">
                  {request.dateTime}
                </td>
                <td className="border border-gray-300 p-2">
                  {request.requestedBy}
                </td>
                <td className="border border-gray-300 p-2">{request.status}</td>
                <td className="border border-gray-300 p-2">
                  <button className="bg-blue-900 text-white p-2 rounded-md">
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Facilities;

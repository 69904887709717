import React, { useEffect } from "react";
import InputBox from "../Components/InputBox";
import {
  createNewDocument,
  getDocDataSnap,
  setDocData,
  deleteDocById,
} from "../../../utils/firebase/Firebase.utils";
import SelectInput from "../Components/SelectInput";
import toast from "react-hot-toast";

function AddResidentForm({
  addMode,
  setAddMode,
  setViewMode,
  viewMode,
  resident,
}) {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    const fetchResidentDetails = async () => {
      getDocDataSnap("residents", resident, (data) => {
        setFormData(data);
      });
    };

    if (viewMode) {
      fetchResidentDetails();
      setDisabled(true);
    }

    if (addMode) {
      setDisabled(false);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelect = (e, name) => {
    setFormData((prev) => ({ ...prev, [name]: e.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const dataObject = {
      ...formData,
      identifier: `Phase ${formData.phase}- Block ${formData.block}- Lot ${formData.lot}`,
    };

    await createNewDocument("residents", dataObject);
    setFormData({});
    setIsProcessing(false);
    setAddMode(false);
    toast.success("Resident Added Successfully");
  };

  const options = [
    { value: "House and Lot", label: "House and Lot" },
    { value: "Lot Only", label: "Lot Only" },
  ];

  const handleEdit = () => {
    setEditMode(!editMode);
    setDisabled(!disabled);
  };

  const handleSubmitEdit = async () => {
    setIsProcessing(true);
    await setDocData("residents", resident, formData);
    setIsProcessing(false);
    setEditMode(false);
    setDisabled(true);
    toast.success("Changes Saved Successfully");
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this resident?")) {
      setIsProcessing(true);
      await deleteDocById("residents", resident);
      setIsProcessing(false);
      setViewMode(false);
      toast.success("Resident Deleted Successfully");
    } else {
      toast.error("Deletion Cancelled");
      return;
    }
  };

  return (
    <div className=" text-left p-2 text-sm">
      <div className="uppercase font-bold">
        {addMode ? "Add Resident" : "Resident Details"}
      </div>
      <div className="flex items-center justify-normal gap-4 underline">
        <button onClick={handleEdit}>
          {editMode ? "Cancel Edit" : "Edit"}
        </button>
        <button onClick={handleDelete}>Delete</button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-start justify-start gap-4 mt-4">
          <div className="w-1/2 h-full space-y-2">
            <div>Basic Information</div>
            <InputBox
              label="Owner 1"
              type="text"
              placeholder="Owner 1"
              name="owner1"
              required
              onChange={handleChange}
              value={formData.owner1}
              disabled={disabled}
            />
            <InputBox
              label="Owner 2"
              type="text"
              placeholder="Owner 2"
              name="owner2"
              onChange={handleChange}
              value={formData.owner2}
              disabled={disabled}
            />
            <InputBox
              label="Address"
              type="text"
              placeholder="Address"
              name="address"
              required
              onChange={handleChange}
              value={formData.address}
              disabled={disabled}
            />
            <InputBox
              label="Email"
              type="email"
              placeholder="Email"
              name="email"
              required
              onChange={handleChange}
              value={formData.email}
              disabled={disabled}
            />
            <InputBox
              label="Phone Number"
              type="tel"
              placeholder="Phone Number"
              name="phoneNumber"
              required
              onChange={handleChange}
              value={formData.phoneNumber}
              disabled={disabled}
            />
          </div>
          <div className="w-1/2 h-full space-y-2">
            <div>Ownership Information</div>
            <InputBox
              label="Phase Number"
              type="text"
              placeholder="Phase Number"
              name="phase"
              required
              onChange={handleChange}
              value={formData.phase}
              disabled={disabled}
            />

            <InputBox
              label="Block Number"
              type="text"
              placeholder="Block Number"
              name="block"
              required
              onChange={handleChange}
              value={formData.block}
              disabled={disabled}
            />

            <InputBox
              label="Lot Number"
              type="text"
              placeholder="Lot Number"
              name="lot"
              required
              onChange={handleChange}
              value={formData.lot}
              disabled={disabled}
            />

            <SelectInput
              label="Unit Type"
              options={options}
              name="unitType"
              onChange={(e) => handleSelect(e, "unitType")}
              disabled={disabled}
              value={options.find(
                (option) =>
                  option.value?.toLowerCase() ===
                  formData.unitType?.toLowerCase()
              )}
            />
            <InputBox
              label="Lot Size in square meters"
              type="number"
              placeholder="Lot Size"
              name="lotSize"
              required
              onChange={handleChange}
              value={formData.lotSize}
              disabled={disabled}
            />

            <InputBox
              label="Google Maps Pin"
              type="text"
              placeholder="Google Maps Pin"
              name="mapsPin"
              onChange={handleChange}
              value={formData.mapsPin}
              disabled={disabled}
            />
            <InputBox
              label="Occupants"
              type="text"
              placeholder="No. of Occupants"
              name="occupants"
              required
              onChange={handleChange}
              value={formData.occupants}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="text-right mt-4">
          {addMode && (
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded-lg"
              type="submit"
            >
              {isProcessing ? "Processing..." : "Add Resident"}
            </button>
          )}

          {editMode && (
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded-lg"
              type="button"
              onClick={handleSubmitEdit}
            >
              {isProcessing ? "Processing..." : "Save Changes"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddResidentForm;

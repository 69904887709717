import React from 'react';

const LifeCalendar = () => {
    const startDate = new Date('1984-07-18'); // Your birth date
    const endDate = new Date('2044-07-18');   // 60th birthday
    const today = new Date('2024-09-10');     // Current date for this example
    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24); // Total days in the life span
    const calendar = [];

    let weekCircles = [];
    const daysInWeek = 7; // 7 days per week
    const weeksInYear = 52; // Approximate weeks in a year

    for (let i = 0; i <= totalDays; i++) {
        const currentDay = new Date(startDate);
        currentDay.setDate(currentDay.getDate() + i);
        
        // Check if the week is completed based on days
        if (i % daysInWeek === 0 && weekCircles.length < weeksInYear) {
            if (currentDay < today) {
                weekCircles.push(<div key={`${i}`} className="w-4 h-4 rounded-full bg-black" />);
            } else {
                weekCircles.push(<div key={`${i}`} className="w-4 h-4 rounded-full bg-white border border-black" />);
            }
        }

        // Add the week's circles to the calendar when a week is complete
        if ((i + 1) % (daysInWeek * weeksInYear) === 0 || currentDay >= endDate) {
            calendar.push(
                <div key={`year-${Math.floor(i / (daysInWeek * weeksInYear))}`} className="flex space-x-1 mb-2">
                    {weekCircles}
                </div>
            );
            weekCircles = []; // Reset for the next year
        }
    }

    // Handle remaining circles if they exist
    if (weekCircles.length > 0) {
        calendar.push(
            <div key={`last-year`} className="flex space-x-1 mb-2">
                {weekCircles}
            </div>
        );
    }

    return (
        <div className="max-w-[800px] mx-auto flex flex-col items-center">
            {calendar}
        </div>
    );
};

export default LifeCalendar;

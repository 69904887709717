import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import AllResidentsTable from "./AllResidentsTable";
import Modal from "../Components/Modal";
import ResidentsDetails from "./ResidentsDetails";
import AddResidentForm from "../Forms/AddResidentForm";
import ResidentDetails from "./ResidentDetails";

function AllResidents() {
  const [residentSummary, setResidentSummary] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedResident, setSelectedResident] = React.useState({});
  const [activeLink, setActiveLink] = React.useState("Resident Information");

  useEffect(() => {
    const fetchResidents = async () => {
      getAllDocsSnap("residents", (residents) => {
        const mappedResidents = residents.map((resident) => {
          return {
            unitOwner: resident.owner1,
            identifier: resident.identifier,
            id: resident.id,
            unitNumber: `Phase ${resident.phase} Block ${resident.block} ${resident.lot}`,
            occupants: resident.occupants,
            address: resident.address,
            lotSize: resident.lotSize + " sqm",
            phoneNumber: resident.phoneNumber,
            email: resident.email,
            unitType: resident.unitType,
            tenanted: resident.tenanted ? "Yes" : "No",

            documents: "View",
          };
        });

        //arrange asc by identifier
        mappedResidents.sort((a, b) => a.identifier - b.identifier);

        setResidentSummary(mappedResidents);
        setIsLoading(false);
      });
    };

    fetchResidents();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const tableHeaders = [
    "Unit Owner",
    "Phase Block and Lot Number",
    "Lot Size",
    "Occupants",
    "Email Address",
    "Phone Number",
    "Tenanted",
    "More Information",
  ];

  const handleViewDetails = () => {
    setViewMode(true);
  };

  return (
    <div>
      <div>
        <div className="mt-4 font-bold text-blue-900 uppercase text-left">
          All Residents
        </div>

        <div className="text-left">
          <button
            className="bg-blue-900 text-white rounded-lg p-2 disabled:bg-gray-500 text-sm"
            disabled={selectedResident.length > 0 ? false : true}
            onClick={handleViewDetails}
          >
            View Details
          </button>
        </div>
        <div className="px-12 mx-auto mt-8">
          <AllResidentsTable
            residents={residentSummary}
            setViewMode={setViewMode}
            setSelectedResident={setSelectedResident}
          />
        </div>
      </div>

      {viewMode && (
        <Modal>
          <div className="relative w-[900px] p-2 ">
            <div
              className="absolute top-0 right-0 underline cursor-pointer text-xs"
              onClick={() => setViewMode(false)}
            >
              Close
            </div>
            <div className="min-w-[800px] min-h-[500px]">
              <ResidentDetails
                selectedResident={selectedResident}
                viewMode={viewMode}
                setViewMode={setViewMode}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AllResidents;

import React from "react";

function NotificationCard({ notification }) {
  return (
    <div className="border-b p-2 bg-white rounded-xl text-xs hover:bg-blue-200">
      <div className="font-bold text-blue-900 uppercase">
        {notification.subject}
      </div>
      <div className="capitalize-first">{notification.message}</div>
    </div>
  );
}

export default NotificationCard;

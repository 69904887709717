import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function DuesUser() {
  return (
    <UserScreenWindow label="Dues">
      <div className="text-center font-bold text-blue-900 mt-4">
        Association Dues
      </div>
      <div className="flex flex-col items-center justify-center">
        <div>You have no outstanding dues</div>
        <div className="text-xs text-gray-600">Thank you for your support</div>
      </div>
    </UserScreenWindow>
  );
}

export default DuesUser;

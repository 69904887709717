import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function WorkPermitConfirmation() {
  const { id } = useParams();
  const navigate = useNavigate();

  const dateToday = new Date().toLocaleDateString();
  return (
    <UserScreenWindow label={"Work Permit Confirmation"}>
      <div className="font-bold text-blue-900 text-sm text-center mt-8">
        Work Permit Confirmation
      </div>

      <div className="bg-white rounded-xl p-2  mt-4">
        <div className="mt-8 text-sm text-gray-600 space-y-4 px-4">
          <div>Your application for permit is being reviewed</div>

          <div>
            Your will receive a confirmation on the status of your application
            within 2 working days
          </div>
          <div>
            If you havent uploaded required documents. Please submit via email
            or hardcopy at the HOA admin office
          </div>
          <div>Thank you for your cooperation</div>
        </div>
        <div className="text-gray-600 font-bold text-xs mt-8 text-center">
          <div>Your Transaction Reference Number</div>
          <div>{id}</div>
          <div>Transaction Date: {dateToday}</div>
        </div>
      </div>

      <div>
        <button
          className="bg-blue-900 hover:bg-blue-700 text-white py-2 px-4 rounded mt-4 w-full"
          onClick={() => navigate(`/requests`)}
        >
          {" "}
          Go to Requests Summary
        </button>
      </div>
    </UserScreenWindow>
  );
}

export default WorkPermitConfirmation;

import React from "react";

function Announcements() {
  const links = ["All", "Announcements", "Newsletters"];
  const [activeLink, setActiveLink] = React.useState("All");

  const activeLinkStyle = "bg-green-500 text-black p-2";
  return (
    <div className="text-blue-900 p-2 space-y-2">
      <div className="flex items-center justify-between">
        <div className="font-bold text-lg">Announcements and Advisory</div>
        <button className="bg-blue-900 text-white rounded-lg px-2 py-1">
          Create
        </button>
      </div>
      <div className="flex items-center justify-between border-2 border-gray-300 p-2">
        <div className="flex items-center rounded-xl border-2 border-gray-300 divide-x-2 overflow-hidden">
          {links.map((link, index) => (
            <div
              key={index}
              className={` cursor-pointer text-sm min-w-20 p-2
                
                ${
                  activeLink === link
                    ? activeLinkStyle
                    : "bg-white text-gray-400"
                }
                `}
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>
        <input
          type="search"
          placeholder="search"
          className="border-2 border-gray-300 px-4 py-2 w-96 rounded-xl"
        />
        <button>Filter</button>
      </div>
      <div className="font-bold text-blue-900">
        Create your first announcements{" "}
      </div>
      <div>Upload announcement template to get started</div>
    </div>
  );
}

export default Announcements;

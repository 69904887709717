import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import TenantDetails from "./TenantDetails";
import Modal from "../Components/Modal";

function AllTenants() {
  const [tenants, setTenants] = React.useState([]);
  const [tenant, setTenant] = React.useState({});
  const [viewMode, setViewMode] = React.useState(false);
  const [residents, setResidents] = React.useState([]);

  const tableHeaders = [
    "Unit Owner",
    "Phase Block and Lot Number",
    "Tenant Name",
    "Occupants",
    "Address",
    "Lease Start",
    "Lease End",
    "Documents",
  ];

  useEffect(() => {
    const fetchResidents = async () => {
      getAllDocsSnap("residents", (residents) => {
        setResidents(residents);
      });
    };

    fetchResidents();
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      getAllDocsSnap("tenants", (tenants) => {
        const mappedTenants = tenants.map((tenant) => {
          const unitOwner = residents.find(
            (resident) => resident.identifier === tenant.lot
          );
          return {
            ...tenant,
            unitOwner: unitOwner?.owner1 || "N/A",
            tenantName: `${tenant.firstName} ${tenant.lastName}`,
            documents: "View",
          };
        });
        setTenants(mappedTenants);
      });
    };

    fetchTenants();
  }, [residents]);

  return (
    <div className="text-xs">
      <table className="w-full mt-4">
        <thead className="text-blue-900">
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index} className="p-2">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {tenants.map((tenant, index) => (
            <tr key={index} className="border-b-2 border-gray-100 capitalize">
              <td className="p-2">{tenant.unitOwner}</td>
              <td className="p-2">{tenant.lot}</td>
              <td className="p-2">{tenant.tenantName}</td>
              <td className="p-2">{tenant.occupants}</td>
              <td className="p-2">{tenant.address}</td>
              <td className="p-2">{tenant.leaseStart}</td>
              <td className="p-2">{tenant.leaseEnd}</td>
              <td className="p-2 text-blue-900 cursor-pointer">
                <button
                  className="underline"
                  onClick={() => {
                    setViewMode(true);
                    setTenant(tenant);
                  }}
                >
                  {tenant.documents}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {viewMode && (
        <Modal>
          <div>
            <div
              className="text-right underline cursor-pointer"
              onClick={() => setViewMode(false)}
            >
              Close
            </div>
            <div className="min-w-[800px] min-h-[500px]">
              <TenantDetails
                viewMode={viewMode}
                setViewMode={setViewMode}
                tenant={tenant}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AllTenants;

import React, { useEffect } from "react";
import BlueNavHeader from "./BlueNavHeader";
import UserScreenWindow from "./UserScreenWindow";
import {
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function GuestCardDetails() {
  const [details, setDetails] = React.useState({});
  const [guests, setGuests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [inProcess, setInProcess] = React.useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);

  const isApprover =
    currentUser.roles.includes("admin") ||
    currentUser.roles.includes("security");

  useEffect(() => {
    const fetchDetails = async () => {
      const data = await getDocDataSnap("guests", id, (data) => {
        setDetails(data);
        setGuests(data.guests);
        setIsLoading(false);
      });
    };
    fetchDetails();
  }, []);

  const handleChange = (e) => {};

  //convert string to time
  const timeIn = new Date(details.timeIn);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const handleAdmit = async () => {
    setInProcess(true);

    const data = {
      ...details,
      status: "admitted",
      timeIn: new Date().toISOString(),
    };
    await setDocData("guests", id, data);
    toast.success("Guest Admitted");
    setInProcess(false);
  };

  const handleEditDetails = () => {
    if (details.timeIn) {
      toast.error("Guest already admitted");
      return;
    }
    navigate(`/edit-guest-card/${id}`);
  };

  return (
    <UserScreenWindow label="Guest Card Details">
      <div className="text-zinc-600">
        <div className="p-2 rounded-lg bg-white mt-2 mx-2">
          <div className="font-bold">Date and Time</div>
          <input type="date" value={details.date} onChange={handleChange} />
          <input type="time" value={details.time} onChange={handleChange} />
        </div>

        <div>
          {details.linkedIdentifiers && (
            <div className="bg-white rounded-lg m-2">
              <div className="p-2">House to be visited</div>
              <div className="p-2">
                {details.linkedIdentifiers.map((identifier, index) => (
                  <div key={index}>{identifier}</div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="bg-white rounded-lg m-2">
          <div className="p-2">Guest Names</div>
          <div className="p-2">
            {details.guests.map((guest, index) => (
              <div key={index} className="grid grid-cols-2">
                <div>{guest.name}</div>
                <div>{guest.plateNumber}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-center">
          <QRCodeSVG
            value={`https://talielegho.vercel.app/guest-card-details/${id}`}
          />
        </div>

        {details.timeIn && (
          <div>
            <div className="font-bold">Time In</div>
            <div>{timeIn.toLocaleString()}</div>
          </div>
        )}

        {isApprover === false && (
          <button
            className="bg-blue-900 text-white p-2 mt-4 w-full rounded-lg disabled:bg-gray-300"
            onClick={handleEditDetails}
            disabled={details.timeIn}
          >
            {details.timeIn
              ? "Admitted"
              : inProcess
              ? "Processing"
              : "Edit Details"}
          </button>
        )}

        {isApprover === true && (
          <div className="flex items-center justify-center gap-4">
            <button
              className="bg-green-900 text-white p-2 mt-4 w-1/2 rounded-lg disabled:bg-gray-300"
              onClick={handleAdmit}
              disabled={details.timeIn}
            >
              {details.timeIn
                ? "Admitted"
                : inProcess
                ? "Processing"
                : "Admit Guest"}
            </button>
          </div>
        )}
      </div>
    </UserScreenWindow>
  );
}

export default GuestCardDetails;

import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function AdminUser() {
  return (
    <UserScreenWindow label="Administration">
      <div className="text-center mt-4 font-bold">
        HOA Bulletin of Information
      </div>
      <div className="p-2 bg-white rounded-xl space-y-8 mt-8">
        <div className="font-bold text-gray-600 text-sm">Announcements</div>

        <div className="font-bold text-gray-600 text-sm">
          Rules and Regulations
        </div>

        <div className="font-bold text-gray-600 text-sm">
          Deed of Restrictions
        </div>

        <div className="font-bold text-gray-600 text-sm">
          Downloadable Forms
        </div>

        <div>
          <div className="font-bold text-gray-600 text-sm">
            Payment Channels
          </div>
          <div>Bank of Philippine Islands</div>
          <div>Bank Account Number: 0091 0619 96</div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default AdminUser;

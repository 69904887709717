import React, { useEffect, useRef } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocsSnap, addNewDoc } from "../../utils/firebase/Firebase.utils";
import MessageCard from "./MessageCard";
import MessageSenderCard from "./MessageSenderCard";
import { useSelector } from "react-redux";

function GroupChat() {
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState({});
  const messagesEndRef = useRef(null); // Create a ref for the messages end

  useEffect(() => {
    const fetchGroupChats = async () => {
      const messages = await getAllDocsSnap("groupChat", (messages) => {
        // Arrange messages by timestamp
        messages.sort((a, b) => {
          return a.dateTime.seconds - b.dateTime.seconds;
        });

        setMessages(messages);
        setLoading(false);
      });
    };

    fetchGroupChats();
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the messages whenever they change
    scrollToBottom();
  }, [messages]); // Depend on messages to scroll when new messages are received

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (loading) {
    return (
      <UserScreenWindow label={"Group Chat"}>
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    const docObject = {
      message: formData.message,
      displayName: currentUser.displayName,
      user: currentUser.email,
      dateTime: new Date(),
    };
    setFormData({});
    await addNewDoc("groupChat", docObject);
    scrollToBottom(); // Scroll to the bottom after sending a new message
  };

  return (
    <UserScreenWindow label={"Group Chat"}>
      <div className="">
        <div className="overflow-y-scroll">
          <div className="pt-8">
            {messages.map((message, index) =>
              message.user === currentUser.email ? (
                <MessageSenderCard key={index} message={message} />
              ) : (
                <MessageCard key={index} message={message} />
              )
            )}
            <div ref={messagesEndRef} />
            {/* This will be used to scroll to the bottom */}
          </div>
        </div>
      </div>
      <div className="max-w-[450px] w-full flex items-center justify-center gap-4  z-20 bg-gray-300 p-2 rounded-lg shadow-lg h-[104px] fixed -translate-x-2 bottom-0 mx-auto ">
        <input
          className="p-2 border-2 border-gray-300 rounded-lg w-[calc(100%-80px)]"
          type="text"
          name="message"
          onChange={handleChange}
          value={formData.message || ""}
          placeholder="Type your message here"
        />
        <button
          className="bg-green-500 text-white p-2 rounded-lg  disabled:bg-opacity-50"
          onClick={handleSubmit}
          disabled={!formData.message}
        >
          Send
        </button>
      </div>
    </UserScreenWindow>
  );
}

export default GroupChat;

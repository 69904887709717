import React from "react";
import BlueNavHeader from "./BlueNavHeader";

function UserScreenWindow({ children, label }) {
  return (
    <div className="min-h-[calc(100vh-128px)] text-left">
      <BlueNavHeader label={label} />
      <div className="p-2 pt-16">{children}</div>
    </div>
  );
}

export default UserScreenWindow;

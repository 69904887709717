import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";

function GatepassConfirmation() {
  const { id } = useParams();
  const [gatepass, setGatepass] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGatepass = async () => {
      const data = await getDocData("gatePass", id);
      setGatepass(data);
      setIsLoading(false);
    };
    fetchGatepass();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Gate Pass Confirmation">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const date = gatepass.requestDateTime.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <UserScreenWindow label="Gate Pass Confirmation">
      <div className="text-center font-bold text-blue-900 mt-8">
        Gate Pass Confirmation
      </div>
      <div className="mt-8 flex flex-col items-center justify-center p-4 text-sm text-center">
        <div className="font-bold text-gray-600">
          Your Gate Pass Request is Submitted
        </div>
        <div className="text-gray-600 font-bold">Request Number:</div>

        <div className="text-blue-900">{id}</div>
        <div className="text-sm text-gray-600">{date}</div>
      </div>

      <div>
        <button
          className="w-full bg-blue-900 text-white rounded-lg p-2"
          onClick={() => navigate("/dashboard")}
        >
          Back to Home
        </button>
      </div>
    </UserScreenWindow>
  );
}

export default GatepassConfirmation;

import React, { useEffect } from "react";

import {
  getDocDataSnap,
  setDocData,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { useState } from "react";
import toast from "react-hot-toast";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";

function TenantDocuments({ tenantId }) {
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [tenant, setTenant] = useState({});
  const [activeUpload, setActiveUpload] = useState("");

  useEffect(() => {
    const fetchTenant = async () => {
      getDocDataSnap("tenants", tenantId, (data) => {
        setTenant(data);
      });
    };
    fetchTenant();
  }, []);

  const handleUpload = (e, fileName) => {
    setActiveUpload(fileName);
    e.preventDefault();
    const file = e.target.files[0];
    const storageRef = ref(storage, `tenants/${tenantId}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setDocData("tenants", tenantId, { [fileName]: downloadURL });
          toast.success(`${fileName} Uploaded Successfully`);
          setProgress(0);
        });
      }
    );
  };

  const requiredFiles = ["Lease Contract", "Valid ID", "Move In Authorization"];

  return (
    <div>
      <div className="font-bold ">Upload Tenant Documents</div>

      {requiredFiles.map((file, index) => (
        <div className="flex items-center gap-4 mt-8">
          <div>
            {tenant[file] ? (
              <IoIosCheckmarkCircle className="text-green-500" />
            ) : (
              <RiErrorWarningFill className="text-red-500" />
            )}
          </div>
          <label className="text-gray-600 font-bold w-60 text-left ">
            {file}
          </label>
          <div className="flex flex-col items-start justify-center">
            <input
              type="file"
              name={file}
              onChange={(e) => handleUpload(e, file)}
            />
            <div className="h-2">
              {activeUpload === file && progress > 0 && (
                <progress value={progress} max="100">
                  {progress}%
                </progress>
              )}
            </div>
          </div>
          {tenant[file] ? (
            <a
              href={tenant[file]}
              target="_blank"
              className="underline font-bold"
            >
              View {file}
            </a>
          ) : (
            <div className="text-gray-500">No {file} Uploaded</div>
          )}
        </div>
      ))}
    </div>
  );
}

export default TenantDocuments;

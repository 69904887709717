import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { v4 } from "uuid";
import { getDocData } from "../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";

function PaymentConfirmed() {
  const [billDetails, setBillDetails] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchBillDetails = async () => {
      const data = await getDocData("billings", id);
      setBillDetails(data);
      setIsLoading(false);
    };
    fetchBillDetails();
  }, []);

  const newDateString = new Date().toLocaleDateString();

  if (isLoading) {
    return (
      <UserScreenWindow label="Payment Confirmation">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label={"Payment Confirmation"}>
      <div className="flex flex-col items-center justify-center mt-8 font-bold text-gray-600">
        <div>Thank you for your payment. </div>
        <div>We shall validate your payment soon</div>
      </div>

      <div className="mt-8 flex flex-col p-4 text-sm items-center justify-center">
        <div>Your Transaction Number</div>
        <div className="text-blue-900 text-sm">{v4()}</div>
        <div>Payment Date: {newDateString}</div>
        <div>
          Amount: Php{" "}
          {parseFloat(billDetails.amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>

        <div className="mt-8 flex flex-col items-center justify-center text-gray-600 gap-4">
          <div>Powered by</div>
          <div>
            <img
              className="w-32"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/PayMongo_Logo.svg/2560px-PayMongo_Logo.svg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default PaymentConfirmed;

import React, { useEffect } from "react";
import JobOrders from "./JobOrders";
import WorkPermits from "./WorkPermits";
import WaterMeterReading from "./WaterMeterReading";
import { useSearchParams } from "react-router-dom";

function PropertyManagement() {
  const [activeLink, setActiveLink] = React.useState("Job Orders");
  const [searchParams, setSearchParams] = useSearchParams();

  const searchType = searchParams.get("type");

  const links = [
    "Job Orders",
    "Approvals and Work Permits",
    "Water Meter Reading",
  ];

  const activeLinkStyle =
    "text-green-600 border-b-2 border-green-600 p-b-2 font-bold";

  useEffect(() => {
    if (searchType) {
      setActiveLink(searchType);
    }
  }, []);

  useEffect(() => {
    setSearchParams({ type: activeLink });
  }, [activeLink]);

  return (
    <div className="p-2">
      <div className="flex items-center justify-between bg-gray-300 p-2 rounded-lg">
        <div className="flex items-center justify-between gap-4 w-full">
          {links.map((link, index) => (
            <div
              key={index}
              className={`${
                activeLink === link ? activeLinkStyle : "text-gray-600"
              } cursor-pointer`}
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>
      </div>
      {
        {
          "Job Orders": <JobOrders />,
          "Approvals and Work Permits": <WorkPermits />,
          "Water Meter Reading": <WaterMeterReading />,
        }[activeLink]
      }
    </div>
  );
}

export default PropertyManagement;

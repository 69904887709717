import React from "react";

function Contacts() {
  return (
    <div className="text-blue-900 font-bold">
      Emergency Contact Information and HOA Directory
    </div>
  );
}

export default Contacts;

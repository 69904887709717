import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import InputBox from "../Login/InputBox";

function BlueNavHeader({ label }) {
  return (
    <div onClick={() => window.history.back()}>
      <div className="bg-blue-900 text-white flex items-center justify-start gap-4 h-12 fixed top-0 max-w-[450px] w-screen z-20">
        <div>
          <IoIosArrowBack />
        </div>
        <div>{label}</div>
      </div>
    </div>
  );
}

export default BlueNavHeader;

import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import CheckBox from "./CheckBox";
import { motion } from "framer-motion";
import ButtonBlue from "../Login/ButtonBlue";

function MaintenanceRequest() {
  const requestTypes = [
    "plumbing",
    "electrical",
    "carpentry",
    "airconditioning",
    "painting",
  ];

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.3, type: "tween" }}
    >
      <div className="bg-blue-900 h-12 text-white flex items-center justify-normal gap-4 p-2">
        <button onClick={() => window.history.back()}>
          <IoIosArrowBack className="" />
        </button>
        Maintenance Request
      </div>
      <div className="text-blue-900 font-bold text-left p-2">
        Select your request
      </div>
      <div className="grid grid-cols-2 p-2 text-blue-900">
        {requestTypes.map((request, index) => (
          <CheckBox key={index} name={request} label={request} />
        ))}
      </div>
      <div>
        <div className="text-left text-blue-900 p-2">
          <div>
            <div>House Number</div>
            <input
              className="border border-blue-900 rounded-lg p-2 w-32"
              placeholder="House Number"
              type="text"
              name="houseNumber"
            />
          </div>
        </div>
      </div>

      <div className="text-left p-2 text-blue-900">
        <div>Preferred Time</div>
        <input
          className="border border-blue-900 rounded-lg p-2 w-32"
          type="time"
        />
      </div>

      <div className="text-left p-2 text-blue-900">
        <div>Description</div>
        <div>
          <textarea
            className="border border-blue-900 rounded-lg p-2 w-full"
            placeholder="Description"
            name="description"
          />
        </div>
      </div>

      <div className="text-left text-blue-900 p-2">
        <div>Upload Photo</div>
        <div className="border border-blue-900 rounded-lg p-2 w-full h-32">
          <input type="file" name="photo" />
        </div>
      </div>

      <div>
        <ButtonBlue label={"Submit"} />
      </div>
    </motion.div>
  );
}

export default MaintenanceRequest;

import React, { useEffect } from "react";
import { FiFilter } from "react-icons/fi";
import IncidentReports from "./IncidentReports";
import { useSearchParams } from "react-router-dom";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";

function Security() {
  const [activeLink, setActiveLink] = React.useState("Overview");
  const links = ["Overview", "Gate Pass", "Guest Pass", "Incident Report"];
  const [filteredRequests, setFilteredRequests] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [requests, setRequests] = React.useState([]);
  const [guestRequests, setGuestRequests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const searchType = searchParams.get("type");

  const activeLinkStyle = "bg-green-500 text-black p-2 rounded-md ";

  useEffect(() => {
    const fetchGuestRequests = async () => {
      const guestRequests = getAllDocsSnap("guests", (data) => {
        const guestArray = data.map((doc) => {
          return {
            id: doc.id,
            requestType: "Guest Pass",
            unit: doc.linkedIdentifiers
              ? doc.linkedIdentifiers[0]
              : "No House Identified",
            contactNumber: doc.email,
            date: doc.date,
            photo: doc.photo,
            guests: doc.guests.length,
            names: doc.guests.map((guest) => guest.name),
          };
        });
        setGuestRequests(guestArray);
      });
    };
    fetchGuestRequests();
  }, []);

  useEffect(() => {
    setRequests(guestRequests);
    setFilteredRequests(guestRequests);
    setIsLoading(false);
    setSearchParams({ type: "Overview" });
  }, [guestRequests]);

  const tableHeaders = [
    "Request Type",
    "Unit",
    "Contact",
    "Date",
    "Guests",
    "Names",
    "Photo",
    "Description",
    "Actions",
  ];

  useEffect(() => {
    if (searchType) {
      setActiveLink(searchType);
    } else {
      setActiveLink("Overview");
    }
  }, [searchType]);

  useEffect(() => {
    setFilteredRequests(requests);

    if (searchType === "Overview") {
      setFilteredRequests(requests);
    }

    if (searchType === "Gate Pass") {
      setFilteredRequests(
        requests.filter((request) => request.requestType === "Gate Pass")
      );
    }
    if (searchType === "Guest Pass") {
      setFilteredRequests(
        requests.filter((request) => request.requestType === "Guest Pass")
      );
    }
  }, [searchType]);

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div className="text-blue-900 p-2 text-left">
      <div className="flex items-center justify-between">
        <div className="font-bold text-xl ">Security</div>
        <div className="space-x-2">
          <button className="bg-blue-900 text-white p-2 rounded-md">
            Edit
          </button>
          <button className="bg-blue-900 text-white p-2 rounded-md">
            Print
          </button>
        </div>
      </div>
      <div className="flex items-center justify-normal gap-4">
        {links.map((link, index) => (
          <div
            key={index}
            className={` cursor-pointer hover:bg-green-500 hover:text-gray-800 text-sm 
            ${
              activeLink === link
                ? activeLinkStyle
                : "bg-white p-2 rounded-md  text-gray-400"
            }
            `}
            onClick={() => setSearchParams({ type: link })}
          >
            {link}
          </div>
        ))}
      </div>
      <div className="p-2 mt-4 flex items-center justify-between border-2 border-gray-300 rounded-lg">
        <input
          type="search"
          className="border-2 border-gray-300 px-4 py-2 w-96 rounded-xl"
          placeholder="Search using Name, Unit, or Request Number"
        />
        <div className="flex items-center justify-center">
          <FiFilter className="text-2xl text-gray-500" />
          Filter
        </div>
      </div>
      <div className="text-xs">
        {activeLink !== "Incident Report" && (
          <table className="w-full mt-4">
            <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index} className="border border-gray-300 p-2">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredRequests.map((request, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 p-2 font-bold">
                    {request.requestType}
                  </td>
                  <td className="border border-gray-300 p-2">{request.unit}</td>
                  <td className="border border-gray-300 p-2">
                    {request.contactNumber}
                  </td>
                  <td className="border border-gray-300 p-2">{request.date}</td>
                  <td className="border border-gray-300 p-2">
                    {request.guests}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {request.names &&
                      request.names.map((name, index) => (
                        <div key={index}>{name}</div>
                      ))}
                  </td>
                  <td className="border border-gray-300 p-2">
                    <img src={request.photo} alt="request" />
                  </td>
                  <td className="border border-gray-300 p-2">
                    {request.description}
                  </td>
                  <td className="border border-gray-300 p-2 space-x-2">
                    <button className="bg-blue-900 text-white p-2 rounded-md">
                      Approve
                    </button>
                    <button className="bg-red-900 text-white p-2 rounded-md">
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div>
        {activeLink === "Incident Report" && (
          <div>
            <IncidentReports />
          </div>
        )}
      </div>
    </div>
  );
}

export default Security;

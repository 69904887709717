import React from "react";

function InputBox({ label, placeholder, type, onChange, name, ...otherprops }) {
  return (
    <div className="flex flex-col items-start justify-end">
      <label className="text-sm font-semibold text-gray-800">{label}</label>
      <input
        className="border p-2 w-full rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        {...otherprops}
      />
    </div>
  );
}

export default InputBox;

import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function WaterServiceUser() {
  return (
    <UserScreenWindow label="Water Delivery">WaterServiceUser</UserScreenWindow>
  );
}

export default WaterServiceUser;

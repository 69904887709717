import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function Tasks() {
  return (
    <UserScreenWindow label="Tasks">
      <div className="font-bold text-blue-900 text-center text-sm mt-8">
        You have no pending tasks
      </div>
    </UserScreenWindow>
  );
}

export default Tasks;

import React from "react";

function IncidentReports() {
  const incidentReports = [
    {
      id: 1,
      dateTime: "12/12/2021 12:00",
      location: "A-101",
      description: "Theft of Bicycle",
      status: "Resolved",
      reportedBy: "John Doe",
      photo: "https://placehold.co/100",
      dateResolved: "12/12/2021 12:30",
      documents: [],
      securityOfficer: "Jane Doe",
    },
    {
      id: 2,
      dateTime: "12/12/2021 12:00",
      location: "A-101",
      description: "Theft of Bicycle",
      status: "For Investigation",
      reportedBy: "John Doe",
      photo: "https://placehold.co/100",
      dateResolved: "12/12/2021 12:30",
      documents: [],
      securityOfficer: "Jane Doe",
    },
    {
      id: 3,
      dateTime: "12/12/2021 12:00",
      location: "A-101",
      description: "Theft of Bicycle",
      status: "Resolved",
      reportedBy: "John Doe",
      photo: "https://placehold.co/100",
      dateResolved: "12/12/2021 12:30",
      documents: [],
      securityOfficer: "Jane Doe",
    },
    {
      id: 4,
      dateTime: "12/12/2021 12:00",
      location: "A-101",
      description: "Theft of Bicycle",
      status: "Resolved",
      reportedBy: "John Doe",
      photo: "https://placehold.co/100",
      dateResolved: "12/12/2021 12:30",
      documents: [],
      securityOfficer: "Jane Doe",
    },
  ];

  const tableHeaders = [
    "Date/Time",
    "Location",
    "Description",
    "Status",
    "Reported By",
    "Photo",
    "Date Resolved",
    "Documents",
    "Security Officer",
    "Actions",
  ];

  return (
    <div>
      <div className="mt-2">
        <div className="flex items-center gap-4 mb-4">
          <button className="bg-blue-900 text-white rounded-lg px-2 py-1">
            Add Incident Report
          </button>
          <button className="bg-blue-900 text-white rounded-lg px-2 py-1">
            Export
          </button>
        </div>
      </div>
      <div className="text-xs">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="border border-gray-300 p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {incidentReports.map((incidentReport, index) => (
              <tr key={index}>
                <td className="border border-gray-300 p-2">
                  {incidentReport.dateTime}
                </td>
                <td className="border border-gray-300 p-2">
                  {incidentReport.location}
                </td>
                <td className="border border-gray-300 p-2">
                  {incidentReport.description}
                </td>
                <td className="border border-gray-300 p-2">
                  {incidentReport.status}
                </td>
                <td className="border border-gray-300 p-2">
                  {incidentReport.reportedBy}
                </td>
                <td className="border border-gray-300 p-2">
                  <img
                    src={incidentReport.photo}
                    alt="incident"
                    className="w-20 h-20 object-cover"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  {incidentReport.dateResolved}
                </td>
                <td className="border border-gray-300 p-2">
                  {incidentReport.documents.map((document, index) => (
                    <a
                      key={index}
                      href={document}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline"
                    >
                      Document {index + 1}
                    </a>
                  ))}
                </td>
                <td className="border border-gray-300 p-2">
                  {incidentReport.securityOfficer}
                </td>
                <td className="border border-gray-300 p-2 space-x-2">
                  <button className=" bg-blue-900 text-white p-2 rounded-md ">
                    Edit
                  </button>
                  <button className=" bg-red-900 text-white p-2 rounded-md ">
                    Resolve
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default IncidentReports;

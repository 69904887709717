import React from "react";

function MessageCard({ message }) {
  //convert firebase timestamp to date
  const date = message.dateTime.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <div className="bg-white rounded-xl p-2 text-sm mt-2 relative ml-2 mr-10">
      <div className="text-gray-600 font-bold text-xs">
        {message.displayName}
      </div>
      <div className="ml-4 mb-4">{message.message}</div>
      <div className="absolute text-gray-400 text-[8px] font-bold  right-1 bottom-0">
        {date}
      </div>
    </div>
  );
}

export default MessageCard;

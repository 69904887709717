import React from "react";

function BillingCard({ billing }) {
  //date firebase timestamp
  const date = new Date(billing.dueDate.seconds * 1000).toLocaleDateString();

  return (
    <div className="bg-white rounded-xl p-2 shadow-sm hover:bg-blue-300 text-gray-600 text-sm">
      <div className="uppercase font-bold text-xs">{billing.identifier}</div>
      <div>Due Date: {date}</div>
      <div className="capitalize">Billing for: {billing.billingType}</div>
      <div className="capitalize">{billing.description}</div>
      <div className="font-bold">
        Php{" "}
        {parseFloat(billing.amount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    </div>
  );
}

export default BillingCard;

import React from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { MdLocalLaundryService } from "react-icons/md";
import { IoWater } from "react-icons/io5";
import { MdCleaningServices } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function ServicesSection() {
  const requestTypes = [
    {
      label: "Laundry Services",
      icon: <MdLocalLaundryService />,
      link: "/laundry",
    },
    {
      label: "Water Delivery",
      icon: <IoWater />,
      link: "/water",
    },
    {
      label: "Cleaning Services",
      icon: <MdCleaningServices />,
      link: "/cleaning",
    },
    {
      label: "Others",
      icon: <HiOutlineDotsHorizontal />,
      link: "/other-services",
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="text-blue-900">
      <div className="text-left font-bold text-xl text-blue-900">Services</div>
      <div className="flex items-center justify-start gap-2 flex-wrap p-2 rounded-xl">
        {requestTypes.map((request, index) => (
          <div
            className="text-xs flex flex-col items-center justify-center w-20 h-20  rounded-lg"
            key={index}
            onClick={() => navigate(`${request.link}`)}
          >
            <div className="text-4xl">{request.icon}</div>
            <div className="text-xs font-semibold">{request.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicesSection;

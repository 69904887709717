import React from "react";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { Link } from "react-router-dom";

function UserBar() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loadUser({}));
  };

  return (
    <div className="flex items-center justify-center gap-4 text-gray-500">
      <AttachEmailIcon />
      <NotificationsIcon />
      <Link to="/settings">
        <SettingsIcon />
      </Link>
      <AccountCircleIcon />
      <button onClick={handleLogout}>
        <LogoutIcon />
      </button>
    </div>
  );
}

export default UserBar;

import { QrCode } from "@mui/icons-material";
import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdPersonAdd } from "react-icons/io";
import { RiFileHistoryLine } from "react-icons/ri";
import GuestCard from "./GuestCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getDocDataSnap,
  getAllDocsSnap,
  setDocData,
  queryAllDocsByFieldSnap,
} from "../../utils/firebase/Firebase.utils";

function GuestRegistration() {
  const [guestRegistrations, setGuestRegistrations] = React.useState([]);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchGuestRegistrations = async () => {
      const guestRegistrations = await queryAllDocsByFieldSnap(
        "guests",
        "requestedBy",
        currentUser.email,
        (data) => {
          //arrange by date
          const sortedData = data.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
          setGuestRegistrations(sortedData);
        }
      );
    };
    fetchGuestRegistrations();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="min-h-screen min-w-screen">
      <div className="h-12 bg-blue-900 text-white flex items-center justify-start gap-4">
        <div onClick={() => window.history.back()}>
          <IoIosArrowBack />
        </div>
        <div>Guest Registration</div>
      </div>

      <div className="flex items-center justify-normal gap-4 text-xs p-2">
        <div
          className="flex flex-col items-center justify-center"
          onClick={() => navigate("/add-guest")}
        >
          <div className="text-4xl text-green-500">
            <IoMdPersonAdd />
          </div>
          <div>Add Guests</div>
        </div>

        {/* <div
          className="flex flex-col items-center justify-center"
          onClick={() => navigate("/guest-history")}
        >
          <div className="text-4xl text-green-500">
            <RiFileHistoryLine />
          </div>
          <div>Guest History</div>
        </div> */}
      </div>

      <div>
        <div className="text-blue-900 text-left p-2 bg-gray-200 text-sm font-bold">
          Guest Registration History
        </div>
      </div>

      <div className="mt-2">
        {guestRegistrations.map((guest, index) => (
          <div
            className="hover:bg-gray-200"
            key={index}
            onClick={() => navigate(`/guest-card-details/${guest.id}`)}
          >
            <GuestCard key={index} guestDetails={guest} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default GuestRegistration;

import React from "react";
import AddTenantForm from "../Forms/AddTenantForm";
import TenantDocuments from "./TenantDocuments";

function TenantDetails({ viewMode, setViewMode, tenant }) {
  const [activeLink, setActiveLink] = React.useState("Basic Information");
  const [loading, setLoading] = React.useState(true);

  const links = ["Basic Information", "Tenancy Information", "Documents"];

  const inactivePillStyle =
    "rounded-full text-xs px-4 py-1 text-gray-500 cursor-pointer border border-gray-300";

  const activePillStyle =
    "rounded-full text-xs px-4 py-1 text-white cursor-pointer bg-green-500";

  return (
    <div>
      <div className="font-bold mb-8">Tenant Detailed Information</div>

      <div className="flex items-center justify-normal gap-4 mb-4">
        {links.map((link, index) => (
          <div
            key={index}
            className={`${
              activeLink === link ? activePillStyle : inactivePillStyle
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {activeLink === "Basic Information" && (
        <AddTenantForm
          viewMode={viewMode}
          setViewMode={setViewMode}
          tenant={tenant}
        />
      )}

      {activeLink === "Tenancy Information" && (
        <div className="text-gray-700">Tenancy Information</div>
      )}

      {activeLink === "Documents" && (
        <div className="text-gray-700">
          <TenantDocuments tenantId={tenant.id} />
        </div>
      )}
    </div>
  );
}

export default TenantDetails;

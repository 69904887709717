import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function OthersUser() {
  return (
    <UserScreenWindow label="Other Requests">
      <div className="text-center mt-8 font-bold text-blue-900">
        Coming soon...
      </div>
    </UserScreenWindow>
  );
}

export default OthersUser;

import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData, setDocData } from "../../utils/firebase/Firebase.utils";

function NotificationDetails() {
  const { id } = useParams();
  const [notification, setNotification] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchNotification = async () => {
      const data = await getDocData("notifications", id);
      setNotification(data);
      setIsLoading(false);
    };
    fetchNotification();
  }, []);

  //update the notification to read when message is opened
  useEffect(() => {
    if (notification) {
      setDocData("notifications", id, { ...notification, read: true });
    }
  }, [notification, id]);

  if (isLoading) {
    return (
      <UserScreenWindow>
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const date = notification.dateTime.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <UserScreenWindow label={notification.subject.toUpperCase()}>
      <div className="text-xs text-gray-600">Date: {date}</div>
      <div className="capitalize mb-8 font-bold text-sm text-gray-600">
        Subject: <span>{notification.subject}</span>
      </div>
      <div className="text-gray-600 font-bold text-sm">Message</div>
      <div className="text-gray-600 capitalize-first">
        {notification.message}
      </div>
    </UserScreenWindow>
  );
}

export default NotificationDetails;

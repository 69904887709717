import React from "react";

function ButtonBlue({ label, type, onSubmit }) {
  return (
    <div>
      <button
        className="bg-blue-600 text-white p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
        onSubmit={onSubmit}
        type={type}
      >
        {label}
      </button>
    </div>
  );
}

export default ButtonBlue;

import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useNavigate } from "react-router-dom";
import { setDocData, getDocById } from "../../utils/firebase/Firebase.utils";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function Gatepass() {
  const [inProcess, setInProcess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const [assignedId, setAssignedId] = React.useState(v4());
  const [linkedIdentifiers, setLinkedIdentifiers] = React.useState([]);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchIdentifiers = async () => {
      const user = await getDocById("users", currentUser.uid);
      setLinkedIdentifiers(user.linkedIdentifiers);
      setIsLoading(false);
    };
    fetchIdentifiers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  if (isLoading) {
    return (
      <UserScreenWindow label="Gate Pass">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProcess(true);

    //check if all fields are filled
    if (
      !formData.identifier ||
      !formData.deliveryDate ||
      !formData.deliveryTime ||
      !formData.items ||
      !formData.deliveryCompany
    ) {
      toast.error("All fields are required");
      setInProcess(false);
      return;
    }

    const docObject = {
      ...formData,
      user: currentUser.email,
      status: "pending",
      requestDateTime: new Date(),
      requestType: "gatePass",
    };

    await setDocData("gatePass", assignedId, docObject);
    toast.success("Request submitted successfully");
    setInProcess(false);
    navigate("/gatepass-confirmation/" + assignedId);
  };

  return (
    <UserScreenWindow label="Gate Pass">
      <div className="font-bold text-blue-900 text-sm text-center">
        Gate Pass Application
      </div>

      <div>
        <div>
          <form onSubmit={handleSubmit}>
            <label className="text-gray-600 text-sm">House Number</label>
            <select
              className="w-full border border-gray-300 rounded-lg p-2 mt-1"
              name="identifier"
              onChange={handleChange}
            >
              <option value="">Select House Number</option>
              {linkedIdentifiers.map((identifier, index) => (
                <option key={index} value={identifier}>
                  {identifier}
                </option>
              ))}
            </select>

            <label className="text-gray-600 text-sm">Delivery Date</label>
            <input
              type="date"
              className="w-full border border-gray-300 rounded-lg p-2 mt-1"
              name="deliveryDate"
              onChange={handleChange}
            />
            <label className="text-gray-600 text-sm">
              Estimated Time of Delivery
            </label>
            <input
              type="time"
              className="w-full border border-gray-300 rounded-lg p-2 mt-1"
              name="deliveryTime"
              onChange={handleChange}
            />
            <label className="text-gray-600 text-sm">
              Provide list of items to be delivered and quantity
            </label>
            <textarea
              className="w-full border border-gray-300 rounded-lg p-2 mt-1"
              rows="4"
              placeholder="e.g. 1. Refrigerator - 1 Unit, 2. 2 Sofa Beds - 2 Units"
              name="items"
              onChange={handleChange}
            />
            <label className="text-gray-600 text-sm">
              Name of Delivery Company
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-lg p-2 mt-1"
              placeholder="e.g. Crate and Barrel"
              name="deliveryCompany"
              onChange={handleChange}
            />
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded-lg mt-4 w-full"
              type="submit"
            >
              {inProcess ? "Processing..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default Gatepass;

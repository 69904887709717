import React, { useEffect } from "react";
import AddResidentForm from "../Forms/AddResidentForm";
import { getDocDataSnap } from "../../../utils/firebase/Firebase.utils";

function ResidentDetails({ selectedResident, viewMode, setViewMode }) {
  const [activeLink, setActiveLink] = React.useState("Resident Information");
  const [resident, setResident] = React.useState({});

  const links = [
    "Resident Information",
    "Occupants Information",
    "Vehicles",
    "Documents",
  ];

  const inactivePillStyle =
    "rounded-full text-xs px-4 py-1 text-gray-500 cursor-pointer border border-gray-300";

  const activePillStyle =
    "rounded-full text-xs px-4 py-1 text-white cursor-pointer bg-green-500";

  return (
    <div>
      <div className="text-sm font-bold mb-8">Resident Information</div>

      <div className="flex items-center justify-normal gap-4 mb-4">
        {links.map((link, index) => (
          <div
            key={index}
            className={`${
              activeLink === link ? activePillStyle : inactivePillStyle
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      <div>
        {activeLink === "Resident Information" && (
          <AddResidentForm
            resident={selectedResident}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
        )}
      </div>
    </div>
  );
}

export default ResidentDetails;

import { doc } from "firebase/firestore";
import React, { useEffect } from "react";

function WorkPermits() {
  const [activeFilter, setActiveFilter] = React.useState("All");
  const [filteredWorkPermitApplications, setFilteredWorkPermitApplications] =
    React.useState([]);

  const filters = ["All", "Pending", "Approved", "Rejected"];

  const workPermitApplications = [
    {
      id: 1,
      unit: "Unit 101",
      name: "John Doe",
      requestNo: "WP-101",
      status: "Pending",
      date: "2021-09-01",
      documents: [],
      description: "Renovation of the kitchen",
      remarks: "",
      actionTaken: "",
    },
    {
      id: 2,
      unit: "Unit 102",
      name: "Jane Doe",
      requestNo: "WP-102",
      status: "Approved",
      date: "2021-09-02",
      documents: [],
      description: "Renovation of the living room",
      remarks: "",
      actionTaken: "",
    },
    {
      id: 3,
      unit: "Unit 103",
      name: "John Smith",
      requestNo: "WP-103",
      status: "Rejected",
      date: "2021-09-03",
      documents: [],
      description: "Renovation of the bathroom",
      remarks: "",
      actionTaken: "",
    },
  ];

  const tableHeaders = [
    "Unit",
    "Name",
    "Request No.",
    "Status",
    "Date",
    "Documents",
    "Description",
    "Remarks",
    "Action Taken",
  ];

  useEffect(() => {
    if (activeFilter === "All") {
      setFilteredWorkPermitApplications(workPermitApplications);
    } else {
      setFilteredWorkPermitApplications(
        workPermitApplications.filter(
          (application) => application.status === activeFilter
        )
      );
    }
  }, [activeFilter]);

  return (
    <div className="p-2 text-left">
      <div className="text-xl font-bold text-blue-900 text-left">
        Request for Approvals and Work Permits
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start gap-4 mt-2">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`cursor-pointer rounded-full px-4 py-1 text-sm border-2 border-gray-300 ${
                activeFilter === filter
                  ? "bg-green-500 text-white font-bold"
                  : ""
              }`}
              onClick={() => setActiveFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
        <div>
          <button className="bg-blue-900 px-4 py-2 text-white rounded-lg">
            Print
          </button>
        </div>
      </div>
      <div className="mt-4 border-2 border-gray-300 p-2 rounded-lg flex items-center justify-between">
        <div>
          <input
            type="search"
            className="border-2 border-gray-300 px-4 py-2 w-96 rounded-lg"
            placeholder="Search Request No. or Name"
          />
        </div>
        <div>Filter</div>
      </div>
      <div className="text-xs">
        <table className="w-full mt-4">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="border-2 border-gray-300 p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredWorkPermitApplications.map((application, index) => (
              <tr key={index} className="border-2 border-gray-300">
                <td className="border-2 border-gray-300 p-2">
                  {application.unit}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.name}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.requestNo}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.status}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.date}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  <button className="bg-blue-900 text-white px-2 py-1 rounded-lg">
                    View documents
                  </button>
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.description}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.remarks}
                </td>
                <td className="border-2 border-gray-300 p-2 space-x-2">
                  <button className="bg-green-500 text-white px-2 py-1 rounded-lg">
                    Approve
                  </button>
                  <button className="bg-red-500 text-white px-2 py-1 rounded-lg">
                    Decline
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WorkPermits;

import React from "react";
import Select from "react-select";

function SelectInput({ options, label, name, onChange, value }) {
  return (
    <div>
      <label>{label}</label>
      <Select options={options} name={name} onChange={onChange} value={value} />
    </div>
  );
}

export default SelectInput;

import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function GuestHistory() {
  return (
    <UserScreenWindow label="Guest History">GuestHistory</UserScreenWindow>
  );
}

export default GuestHistory;

import React from "react";

function Services() {
  const [activeService, setActiveService] = React.useState("");

  const services = ["laundry", "cleaning", "water"];

  const activeServiceStyle = "bg-green-500  ";

  return (
    <div className="text-blue-900 p-2">
      <div className="flex items-center justify-between">
        <div className="font-bold text-lg ">Accredited Suppliers</div>
        <div className="flex items-center justify-normal gap-4">
          <div>Edit</div>
          <div>Filter</div>
        </div>
      </div>
      <div className="flex items-center justify-between border border-gray-300 px-4 py-2 text-xs">
        <div className="flex items-center justify-normal rounded-lg border border-gray-300 divide-x">
          {services.map((service, index) => (
            <div
              key={index}
              className={`p-2 cursor-pointer min-w-20 capitalize ${
                activeService === service ? activeServiceStyle : ""
              }`}
              onClick={() => setActiveService(service)}
            >
              {service}
            </div>
          ))}
        </div>
        <input
          type="search"
          placeholder="Search by Name"
          className="border border-gray-300 w-96 rounded-lg px-4 py-2"
        />
        <div>Filter</div>
      </div>
    </div>
  );
}

export default Services;

import React from "react";

function Billing() {
  const billings = [
    {
      id: 1,
      soaNo: "SOA-0001",
      unitNo: "Unit 101",
      billingDate: "2021-10-01",
      status: "Paid",
      amount: 1000,
      paymentDate: "2021-10-01",
    },
    {
      id: 2,
      soaNo: "SOA-0002",
      unitNo: "Unit 102",
      billingDate: "2021-10-01",
      status: "Unpaid",
      amount: 2000,
      paymentDate: "2021-10-01",
    },
    {
      id: 3,
      soaNo: "SOA-0003",
      unitNo: "Unit 103",
      billingDate: "2021-10-01",
      status: "Paid",
      amount: 3000,
      paymentDate: "2021-10-01",
    },
    {
      id: 4,
      soaNo: "SOA-0004",
      unitNo: "Unit 104",
      billingDate: "2021-10-01",
      status: "Unpaid",
      amount: 4000,
      paymentDate: "2021-10-01",
    },
  ];

  const tableHeaders = [
    "SOA No.",
    "Unit No.",
    "Billing Date",
    "Status",
    "Amount",
    "Payment Date",
    "Actions",
  ];

  return (
    <div className="text-blue-900 p-2 space-y-2">
      <div className="flex items-center justify-between">
        <div className="font-bold text-xl">Billing</div>
        <div className="flex items-center justify-normal gap-4">
          <button className="bg-blue-900 text-white px-4 py-2 rounded-md">
            Create Billing
          </button>
          <button className="bg-blue-900 text-white px-4 py-2 rounded-md">
            Download PDF Report
          </button>
        </div>
      </div>
      <div className="border border-gray-300 p-2 rounded-lg flex items-center justify-between ">
        <input
          type="search"
          placeholder="Search by Billing No. or Name"
          className="rounded-lg border border-gray-300 px-4 py-2 min-w-96"
        />
        <button>Filter</button>
      </div>
      <div className="text-xs">
        <table className="w-full border border-gray-300">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="border border-gray-300 p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {billings.map((billing, index) => (
              <tr key={index} className="border border-gray-300">
                <td className="border border-gray-300 p-2">{billing.soaNo}</td>
                <td className="border border-gray-300 p-2">{billing.unitNo}</td>
                <td className="border border-gray-300 p-2">
                  {billing.billingDate}
                </td>
                <td className="border border-gray-300 p-2">{billing.status}</td>
                <td className="border border-gray-300 p-2">{billing.amount}</td>
                <td className="border border-gray-300 p-2">
                  {billing.paymentDate}
                </td>
                <td className="border border-gray-300 p-2">
                  <button className="bg-blue-900 text-white px-4 py-2 rounded-md">
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Billing;
